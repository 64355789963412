.TimePicker-wrapper{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.TimePicker-label {
    background-color: white;
    color: #686972;
    position: absolute;
    font-weight: 400;
    padding: 0 2px;
    top: -8px;
    margin-left: 13px;
    z-index: 1;
    font-size: 11px;
    left: 15px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    transition: all 200ms;
}

.rc-time-picker-input {
    height: 34px;
    padding: 6px 12px;
    outline: none;
    font-size: 14px;
    color: #555;
    border: 1px solid #ccc;
}

.rc-time-picker-panel-input {
    font-size: 13px;
}
