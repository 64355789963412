/* light orange #ffe8de; */
/* light gray #F2F2F2; */

/*#EFEAE2 = rgba(239, 234, 226, 0.5)

#D9FDD2*/
.conversation-container {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #a1a1a1;
    position: relative;
}

.searchBar{
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: space-between; /* Push content to the sides */
    height: 35px;
    border-bottom: solid;
    border-width: 1px;
    border-radius: 10px 10px 0 0;
    border-color: gray;
    background-color: white;
}

.searchBarButtons{
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 16px;
    gap: 8px
}
.buttons{
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 2px
}

.minimizeButton, .searchButton, .writeButton, .replyButton, .openDiscussionButton, .minusButton, .deleteButton{
    border: none;
    background-color: transparent;
    color: #6b7280;
    padding: 0;
}

.minimizeButton:hover, .searchButton:hover, .writeButton:hover, .replyButton:hover, .openDiscussionButton:hover, .minusButton:hover, .deleteButton:hover{
    color: #ff9779;
}

.notification-icon {
    margin-left: 16px;
    position: relative; /* Make the container a positioning context */
    display: inline-block; /* Ensure the container wraps the icon */
}

.notificationsButton {
    cursor: auto;
    color: #6b7280
}

.notification-dot {
    position: absolute; /* Position the dot relative to the container */
    top: 0; /* Align to the top */
    right: 0; /* Align to the right */
    width: 8px; /* Size of the dot */
    height: 8px; /* Size of the dot */
    background-color: red; /* Red color for the dot */
    border-radius: 50%; /* Make it a circle */
    border: 2px solid white; /* Optional: Add a white border for better visibility */
}

.container2{
    display: flex;
}

.container2-1{
    flex: 65%;
    background-color: rgba(239, 234, 226, 0.5);
}

.container2-2 {
    max-height: 550px;
    flex: 35%;
    border-left: solid;
    border-width: 1px;
    border-color: gray;
    background-color: white;
    padding: 12px 14px;
    display: flex;
    animation: expand-width 0.5s ease forwards;
    flex-direction: column;
}

.titleSpan {
    color: #6b7280;
}

.container2-2-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.xButton {
    color: #6b7280;
    cursor: pointer;
}

.xButton:hover {
    color: #ff9779;
}

.container2-2-2 {
    display: flex;
    gap: 4px;
}

.container2-2-2 input {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s, box-shadow 0.2s;
}

.container2-2-2 input:focus {
    border-color: #FF6347;
}

.container2-2-2 input::placeholder {
    color: #9ca3af;
}

.container2-2-3 {
    flex: 1; /* Take up remaining space in the parent */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Prevent the parent from expanding */
}

.container2-2-3-1, .container2-2-3-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.container2-2-3-2-nonEmpty {
    flex: 1.5; /* Allow it to grow within the parent's constraints */
    overflow-y: auto; /* Add scroll if content overflows */
    background-color: #f9f9f9;
    border-radius: 8px;
}

.container2-2-3-4-nonEmpty {
    flex: 1; /* Allow it to grow within the parent's constraints */
    overflow-y: auto; /* Add scroll if content overflows */
    background-color: #f9f9f9;
    border-radius: 8px;
}

.container2-2-3-4-Empty, .container2-2-3-2-Empty {
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow-y: auto;
}

.searchedMessage {
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 6px;
    margin-bottom: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* No results message */
.no-results {
    color: #9ca3af;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Message header (sender name and date) */
.searched-message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

/* Message date */
.searched-message-date {
    font-size: 12px;
    color: #9ca3af;
}

.searchedFilesWrapper{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin: 0.5rem 6px 0.5rem 2px;
}


/* System message container */
.message-container.systemSent {
    justify-content: flex-end;
}

/* System message bubble */
.message-bubble.system {
    background-color: #e0f7fa; /* Light cyan background */
    color: #00695c; /* Dark teal text for contrast */
    border-radius: 18px; /* Rounded corners */
    max-width: 90%; /* Slightly wider than regular messages */
    padding: 5px; /* More padding for emphasis */
    font-size: 14px; /* Slightly larger font */
    /*border: 1px solid #80cbc4; /* Light teal border */
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* System message header (if needed) */
.message-bubble.system .message-header {
    margin-bottom: 0.5rem; /* Add spacing below header */
}

/* System message text */
.message-bubble.system .message-text {
    margin: 0; /* Remove default margins */
}

/* System message time */
.message-bubble.system .message-time {
    font-size: 10px;
    opacity: 0.85;
    margin-top: 0.5rem; /* Add spacing above timestamp */
    display: block; /* Ensure it's on a new line */
    text-align: center; /* Center-align timestamp */
}





.conversation-messages {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 15px;
}

.message-container {
    display: flex;
    margin-bottom: 0.5rem;
}

.message-container.sent {
    justify-content: flex-end;
}

/* Highlight animation */
@keyframes highlight {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

.message-container.highlighted {
    animation: highlight 0.4s ease-out;
}

.message-bubble {
    max-width: 80%;
    min-width: 20%;
    padding: 0.5rem;
    border-radius: 18px;
}

.message-bubble.received {
    background-color: white;
    color: #1f2937;
    border-bottom-left-radius: 0;
}

.message-bubble.sent {
    background-color: #D9FDD2;
    color: #1f2937;
    border-bottom-right-radius: 0;
}

.message-input-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    bottom: 2px; /* Ensures it stays at the bottom */
    padding: 4px 15px 6px 15px;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-width: 1px;
    border-color: #FF6347;
}

.message-input {
    flex: 1;
    resize: none;
    border: 1px solid #e5e7eb;
    border-radius: 2.5rem;
    outline: none;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 4px
}

.message-input:focus {
    border-color: #F27A5B;
}

.attachFile-button{
    padding: 14px;
    height: 47px;
    width: 47px;
    border-radius: 5rem;
    border: none;
    background-color: #ff9779;
    color: white;
    transition: background-color 0.4s;
}
.attachFile-button:hover {
    background-color: #F27A5B;
}

.messageFilesWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 0.5rem;
    margin: 0.5rem 6px 0.5rem 2px;
}

.messageFile {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 4px 3px;
    border-radius: 0.375rem;
    text-decoration: none;
    color: inherit;
    transition: background-color 0.2s ease;
    font-size: 11px;
}

.messageFile:hover {
    background-color: rgba(255, 99, 71, 0.3);
}

.messageFile svg {
    color: rgba(0, 0, 0, 0.6);
}

.fileName {
    flex-grow: 1;
    max-width: 70%; /* Ensure it doesn't take too much space */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left; /* Ensure text aligns to the left */
    margin-left: 3px;
}

.fileSize {
    max-width: 50px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 9px;
    margin-left: auto;
}
/*
.messageFile .fileName {
    margin-left: 8px;
}

.messageFile .fileSize {
    color: gray;
}

.messageFilesWrapper{
    margin: 10px 6px 4px;
}
.messageFile{
    display: flex;
    align-items: center;
    font-size: 13px;
    gap: 5px;
    margin-top: 2px
}
*/

.filesUploadPreview {
    display: flex;
    align-items: center; /* Ensures all children align vertically in the center */
    flex-wrap: wrap; /* Allows items to wrap to the next line when they exceed the container width */
    padding: 0.5rem 2rem 0.5rem 1rem;
    background-color: white;
    color: black;
    border: 1px solid #FF6347;
    border-radius: 2.75rem;
    margin-bottom: 0.2rem;
    margin-right: 0.1rem;
    margin-left: 0.1rem;
    gap: 14px; /* Adjusted spacing for better balance */
}

.file-items-wrapper {
    display: flex; /* Enable flexbox for the wrapper */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    align-items: center; /* Align items vertically */
    gap: 0.3rem; /* Adds spacing between the items */
    margin-left: 0.5rem; /* Provides spacing from the FileIcon */
    flex: 1; /* Ensures the file-items-wrapper takes up remaining space */
}

.FileIcon {
    margin-left: 6px;
    color: #888;
    flex-shrink: 0; /* Prevents the icon from shrinking */
}

.deleteFileIcon {
    color: #888;
    margin-left: auto; /* Pushes the X button to the far right */
}

.deleteFileIcon:hover {
    color: #F27A5B;
}


.file-item {
    display: flex;
    align-items: center;
    padding: 4px 3px 4px 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.file-name {
    font-weight: bold;
    font-size: 11px;
}

.file-size {
    margin-left: 10px;
    margin-right: 10px;
    color: #888;
    font-size: 11px;
}

.remove-file-button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
}

.remove-file-button:hover {
    color: #F27A5B;
}

.messageInputContainer {
    display: flex; /* Enable Flexbox */
    align-items: stretch; /* Vertically center the button and input */
    gap: 8px; /* Add spacing between the input and button */
}

.textInput{
    flex: 1;
    height: 100%
}


.send-button {
    flex: 0 0 auto; /* Prevent the button from growing or shrinking */
    padding: 0 16px; /* Add horizontal padding for better appearance */
    border: 1px solid #ccc;/* Remove default border */
    border-radius: 0.5em; /* Add rounded corners */
    align-items: center; /* Center content vertically */
}

.send-button:disabled {
    background-color: #F2F2F2;
    color: #9ca3af;
    cursor: not-allowed;
}

.send-button:not(:disabled) {
    background-color: white;
    color: #6b7280;
    cursor: pointer;
}

.send-button:not(:disabled):hover {
    background-color: #ffe0d0
}


.message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
    gap: 20px;
    padding: 0 3px
}

.sender-name {
    font-size: 14px;
    color: #6b7280;
}

.replyIcon {
    color: #6b7280;
    transition: color 0.2s ease;
    margin: 0 2px 0 0;
}
.replyIcon:hover {
    color: #ff9779;
}

.message-time {
    font-size: 10px;
    opacity: 0.85;
    float: right;
    padding-right: 6px;
    margin-top: 2px;
}

.message-text {
    font-size: 14px;
    white-space: pre-wrap;
    margin-top: 4px;
    margin-bottom: 2px;
    text-align: left;
    overflow-wrap: break-word; /* Fallback for better browser support */
    word-wrap: break-word; /* Legacy property for older browsers */
    padding: 0 3px
}

.message-text p {
    margin: 0;
}

.leaveCommentButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    margin-top: 22px;
    padding: 7px 4px 0 6px;
    border-top: solid;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-width: 1px;
    width: 100%;
    color: #FF6347; /*#ff9779;*/
    font-size: 14px;
    cursor: pointer;
    gap: 6px;
    transition: color 0.2s ease;
}

.icon-left {
    color: #FF6347;
    transition: color 0.2s ease; /* Apply transition to icons as well */
}
.icon-right {
    color: #FF6347;
    margin-left: auto; /* Pushes the right arrow to the far right */
    transition: color 0.2s ease;
}

.leaveCommentButton:hover {
    color: #ff9779;
}
.leaveCommentButton:hover .icon-left,
.leaveCommentButton:hover .icon-right {
    color: #ff9779;
}
.leaveCommentButton:hover .badge {
    background-color: #ff9779;
}


.reply-preview {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background-color: white;
    color: black;
    border-radius: 2.75rem;
    margin-bottom: 0.2rem;
    margin-right: 0.1rem;
    margin-left: 0.1rem;
    gap: 0.3rem;
}

.reply-preview p {
    flex: 1;
    margin: 0;
    font-size: 13px;
    word-break: break-all; /* Forces breaking if no spaces are present */
}

.reply-preview button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
}

.cancelReplyButton:hover {
    color: #F27A5B;
}

.rawInput{
    display: flex;
    align-items: center;
    align-content: center;
    gap: 0.6rem;
}

.editor-wrapper {
    flex: 1;
    margin: 0 6px;
}

.replyIcon2{
    cursor: default !important;
}

.replyMessage-header{
    margin-left: 6px;
    padding: 3px;
    border-left: solid;
    border-radius: 10px;
    border-width: 3px;
    flex: 1;
    text-align: left;
    cursor: pointer;
}

.replyMessage-header.sent{
    border-left-color: green;
    background-color: #F2F2F2;
}

.replyMessage-header.received{
    border-left-color: green;
    background-color: #ffe8de;
}

.cancelReplyButton {
    margin-left: auto; /* Pushes the X button to the far right */
}

.reply-sender-name {
    font-size: 13px;
    color: #6b7280;
    padding-left: 3px;
}

.back-button{
    display: flex;
    align-items: center;
    justify-content: center; /* Aligns the content to the left */
    color: #a1a1a1;
    border-color: black;
    border-radius: 4px 0 0 0;
    border-width: 1px;
    cursor: pointer;
    gap: 8px;
    font-size: 14px;
    height: 100%;
    border-top: none;
    border-right: solid;
    border-bottom: none;
    border-left: none;
    animation: expand-width 0.5s ease forwards; /* Trigger the animation */
    padding: 0;
}

/* Keyframes for expanding width */
@keyframes expand-width {
    0% {
        width: 0; /* Start at width 0 */
    }
    100% {
        width: 50px; /* End at full width */
    }
}


.back-button:hover{
    color: #FF6347;
    border-color: #FF6347;
    background-color: #ffe0d0
}
.back-button:hover .icon-left {
    stroke: #FF6347; /* Change the stroke color of the SVG on hover */
}

.conversation-subMessages{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding: 0 50px 0 50px;
}

.backButtonContainer{
    position: absolute; /* Needed to position the pseudo-element */
    border-top: none;
    border-right: solid;
    border-bottom: none;
    border-left: none;
    border-width: 0px;
    border-color: #FF6347;
    margin: auto;
    height: 100%;
    width: 30px;
    display: flex; /* Enable Flexbox */
    align-items: center; /* Center content vertically */
    justify-content: flex-start; /* Aligns the content to the left */
}

.no-messages-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #888; /* Light gray text */
    font-size: 24px;
    font-style: italic;
}

.grouped-messages {
    text-align: center;
    position: relative;
    max-width: 100%;
}

.message-date {
    display: inline-block;
    position: relative;
    padding: 4px 16px;
    border: 1px solid #6b7280;
    border-radius: 16px;
    font-size: 12px;
    color: #6b7280;
    background-color: white; /* Or your background color */
    margin: 6px 0;
}

.message-date::before,
.message-date::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100px; /* Adjust line length as needed */
    height: 1px;
    background-color: #6b7280;
}


.message-date::before {
    right: 100%;
    margin-right: 10px;
}

.message-date::after {
    left: 100%;
    margin-left: 10px;
}

.nonSeenMessagesBadge {
    position: relative;
    display: block; /* Forces the badge to take up its own line */
    margin: 2px auto 6px auto; /* Centers the badge horizontally and adds vertical spacing */
    padding: 4px 16px;
    font-size: 12px;
    color: #6b7280;
    background-color: transparent;
    text-align: center;
    width: fit-content; /* Makes the badge take only the needed space */
}
.nonSeenMessagesBadge::before,
.nonSeenMessagesBadge::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100px; /* Adjust line length as needed */
    height: 1px;
    background-color: #6b7280;
}


.nonSeenMessagesBadge::before {
    right: 100%;
}

.nonSeenMessagesBadge::after {
    left: 100%;
}

.icon-with-badge {
    position: relative; /* Enables positioning of the badge relative to the icon */
    display: inline-block; /* Ensures the container fits tightly around the icon */
}


.icon-with-badge .badge {
    position: absolute;
    top: -6px; /* Adjust this value to position the badge vertically */
    right: -6px; /* Adjust this value to position the badge horizontally */
    background-color: #FF6347; /* Badge background color */
    color: white; /* Badge text color */
    font-size: 10px; /* Adjust badge text size */
    padding: 2px 3px; /* Adjust padding for badge size */
    border-radius: 100%; /* Makes the badge circular */
    font-weight: bold; /* Makes the number more visible */
    min-width: 16px; /* Ensures badge is round for single-digit numbers */
    text-align: center; /* Centers the text inside the badge */
}

.button-text{
    display: inline-flex; /* Ensures proper alignment and circular shape */
    margin-bottom: 3px;
    margin-left: 6px;
    flex: 1
}


.goUpButton{
    height: 22px;
    width: 22px;
    border: none;
    border-radius: 12px;
    background-color: transparent;
    color: #FF6347;
    display: flex; /* Enable Flexbox */
    align-items: center; /* Center content vertically */
    justify-content: center;
}

.goUpButton:hover {
    color: #ff9779;
}

.goDownButton{
    height: 22px;
    width: 22px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 0px solid #FF6347;
    border-radius: 10px;
    background-color: transparent;
    color: #FF6347;
    display: flex; /* Enable Flexbox */
    align-items: center; /* Center content vertically */
    justify-content: center;
}

.goDownButton:hover {
    color: #ff9779;
}

.goToTheEndButton{
    position: absolute;
    height: 24px;
    width: 24px;
    /*right: 16px;*/
    bottom: 262px;
    border: none;
    background-color: transparent;
    color: #FF6347;
    display: flex; /* Enable Flexbox */
    align-items: center; /* Center content vertically */
    justify-content: center;
}

.goToTheEndButton:hover {
    color: #ff9779;
}

.activeMessagesNavigationBadge {
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    height: 100%; /* Ensure the height is defined for vertical alignment */
    width: 100%; /* Optional: ensure width covers the container if necessary */
    font-size: 12px;
}

.test{
    position: absolute;
    right: 28px;
    transform: translateX(50%); /* Adjust to center */
    bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center content vertically */
}



